$(document).on('turbolinks:load', function () {
  $('input[name="import_items"]').on('change', function () {
    $('input[name="import_item[]"]').prop('checked', $(this).prop('checked'))
  })

  


  $('#custom-labels').on('click', function (e) {
    e.preventDefault();
    let selected_boxes = []
    $('input[name="import_item[]"]:checked').each(function () {
      selected_boxes.push($(this).val());
    });
    if (selected_boxes.length < 1) {
      alert('No orders selected')
    } else {
      $.ajax({
        url: '/imports/' + $(this).data('import-id') + '/generate',
        type: 'POST',
        data: {
          label_ids: selected_boxes.join(',')
        },
        success: function () {
          location.href = window.location.href
        }
      })
    }
  })
})
