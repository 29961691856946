import consumer from "./consumer"

consumer.subscriptions.create("LabelChannel", {
  connected() {
    console.log('connected')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data)
    if (data.item_id && $('#import_item_' + data.item_id).length > 0) {
      $('#import_item_' + data.item_id).replaceWith(data.item_content)
    }
  }
});
